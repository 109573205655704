import * as React from 'react';

import { graphql } from 'gatsby';

import CampaignDetailsModule from '../../modules/campaignDetails';
import { Typography } from '@mui/material';

const CampaignDetailsPage = (props: any) => {
  return (
    <CampaignDetailsModule campaignKey="donation-ambulance" showDonationDescription={true}>

      <Typography variant="h6" paragraph={true}>Bank details</Typography>

      <Typography paragraph={true}>
        <Typography>Beneficiary: NON-GOVERNMENT ORGANIZATION "UNION OF TELEVISION AND FILM INDUSTRY ENTREPRENEURS"</Typography>
        <Typography>Account: UA903348510000000026009129298</Typography>
        <Typography>Beneficiary's bank: FIRST UKRAINIAN INTERNATIONAL BANK</Typography>
        <Typography>SWIFT code: FUIBUA2X</Typography>
        <Typography>Intermediary Bank: Commerzbank AG</Typography>
        <Typography>SWIFT code: COBADEFF</Typography>  
      </Typography>

      <Typography paragraph={true}>
        <b>Please let us know how much you have donated.</b>
      </Typography>

    </CampaignDetailsModule>
  );
};

export default CampaignDetailsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
